import React, { useState } from "react"
import { Global } from "@emotion/core"
import { globalStyles } from "../styles"
import Layout from "../components/layout"
import PageHero from "../components/pageHero"
import Register from "../components/register"
import Footer from "../components/footer"
import styled from "@emotion/styled"

import MobileNavigation from "../components/navigationMobile"
import mediaqueries from "../styles/media"

interface Props {
  location: any
}

const Page404: React.FC<Props> = ({ location }) => {
  const [isOpen, setOpen] = useState(false)
  return (
    <>
      <Global styles={globalStyles} />
      <MobileNavigation isOpen={isOpen} setOpen={setOpen} location={location} />
      <PageHero title={"404"} location={location} />
      <Main>
        <Layout>
          <DemoBodyHolder>404 empty page</DemoBodyHolder>
        </Layout>
        <Register />
        <Footer />
      </Main>
    </>
  )
}

export default Page404

const Main = styled.main`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const DemoBodyHolder = styled.div`
  max-width: 400px;
  margin-left: 10px;
  line-height: 25px;
  margin-top: -200px;
  margin-bottom: 100px;

  & strong {
    color: ${(p: any) => p.theme.colors.secondary};
  }

  & a {
    color: ${(p: any) => p.theme.colors.secondary};
  }

  & p {
    margin-top: 10px;
  }

  ${mediaqueries.desktop`
    margin-top: 0px;
`};
`
